import axios from 'axios';
import config from 'config/config';
import { AuthContext } from 'contexts/Auth';
import { useContext } from 'react';

/**
 * Use this instance generally to call for api endpoints that need authentication
 * It will attempt the call, and if accessToken is expired but refresh token is not,
 * it will attempt to refresh the accessToken and repeat the call once
 */



const useAxios = () => {
  const authContext = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: config.apiBasePath,
  });
  
  const axiosWithAuth = axiosInstance;

  axiosWithAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          // Request a new access token from refresh endpoint
          const { data } = await axios.post('/token/refresh');
  
          // Update the Authorization header and retry the request
          originalRequest.headers['Authorization'] = `Bearer ${data}`;

          // console.log('refreshing token');
          // console.log(data);
          authContext?.decodeAndMapTokenIntoState(data.accessToken);
          authContext?.decodeAndMapTokenIntoState(data.refreshToken);

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error('Refresh token failed', refreshError);
          if(authContext){
            authContext.logout();
          } else {
            console.error('AuthContext not found');
          }
          
        }
      }
  
      return Promise.reject(error);
    }
  );

  return {
    axiosInstance,
    axiosWithAuth
  }
}


export default useAxios;
