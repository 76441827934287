import React, { useEffect } from 'react';
import 'scss/prompt.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import { useNavigate, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { Prompt, PromptState } from '@prisma/client';
import { OpenAIDataInteraction } from '@shared/types';


export default function PromptCreate(){
  const [showAdvancedSettings,  setShowAdvancedSettings]  = React.useState(false);
  const [isReadyForSuperSearch, setIsReadyForSuperSearch] = React.useState(false);
  const [hasMainPromptChanged,  setHasMainPromptChanged]  = React.useState(false);

  const [userMessage,    setUserMessage]    = React.useState('');
  const [systemMessage,  setSystemMessage]  = React.useState('');
  const [generatedList,  setGeneratedList]  = React.useState('');
  const [userMessage2,   setUserMessage2]   = React.useState('');

  const [promptState, setPromptState] = React.useState<PromptState>('created');

  const { promptId } = useParams();

  const { axiosWithAuth } = useAxios();
  const navigate = useNavigate();

  const {data: createdPrompt, mutate: createPrompt, isPending: isCreatingPrompt} = useMutation({
    mutationKey: ['prompts'],
    mutationFn: async () => {
      return await axiosWithAuth.post('prompts', {userMessage}).then((res) => res.data as Prompt);
    },
  });

  const {data: readPrompt, isLoading: isLoadingPrompt} = useQuery({
    queryKey: ['prompts', {promptId}],
    queryFn: async () => {
      return await axiosWithAuth.get(`/prompts/${promptId}`).then((res) => res.data as Prompt);
    },
    retry: false,
    enabled: !!promptId
  })

  const { data: regeneratedPrompt, mutate: regeneratePrompt, isPending: isRegeneratingPrompt } = useMutation({
    mutationKey: ['prompts', {promptId}],
    mutationFn: async () => {
      return await axiosWithAuth.post(`/prompts/${promptId}`, {userMessage}).then((res) => res.data as Prompt);
    }
  })

  const { data: superSearchPromptId, mutate: superSearch, isPending: isSuperSearching } = useMutation({
    mutationKey: ['prompts', {promptId}, 'super-search'],
    mutationFn: async () => {
      return await axiosWithAuth.post(`/prompts/${promptId}/super-search`, {interpretationMessage: userMessage2}).then((res) => res.data as Prompt);
    }
  })

  useEffect(() => {
    if (createdPrompt && !promptId) {
      navigate(`/prompts/${createdPrompt.id}`, {replace: true});
    }
  }, [createdPrompt, promptId, navigate]);

  useEffect(() => {
    if (superSearchPromptId) {
      navigate(`/prompts/${superSearchPromptId}/interests`);
    }
  }, [superSearchPromptId, navigate]);

  useEffect(() => {
    if(readPrompt){
      setPromptState(readPrompt.state);
      setPromptOpenAIDataInteractionsIntoTextAreas(readPrompt);
    }
  }, [readPrompt]);

  useEffect(() => {
    if(regeneratedPrompt){
      setPromptState(regeneratedPrompt.state);
      setPromptOpenAIDataInteractionsIntoTextAreas(regeneratedPrompt);
    }
  }, [regeneratedPrompt]);

  const handleMainPromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(!hasMainPromptChanged) setHasMainPromptChanged(true);
    setUserMessage(e.target.value);
    unsetAdvancedSettingsFields();
  }

  const handleMainPromptBlur = () => {
    if(isRegeneratingPrompt || isCreatingPrompt) return;
    if(hasMainPromptChanged){
      setHasMainPromptChanged(false);
      if(promptId){
        regeneratePrompt();
      } else {
        createPrompt();
      }
    }
  }

  const setPromptOpenAIDataInteractionsIntoTextAreas = (prompt: Prompt) => {
    const openAIDataInteractions = prompt.openAIDataInteractions as unknown as OpenAIDataInteraction[];
    const lastInteraction        = openAIDataInteractions.slice(-1)[0];

    setIsReadyForSuperSearch(true);

    setUserMessage(  lastInteraction.request.messages[0].content as string);
    setSystemMessage(lastInteraction.request.messages[1].content as string);
    setGeneratedList(lastInteraction.request.messages[2].content as string);
    setUserMessage2( lastInteraction.response.choices[0].message.content as string);
  }

  const unsetAdvancedSettingsFields = () => {
    setSystemMessage('');
    setGeneratedList('');
    setUserMessage2('');

    setIsReadyForSuperSearch(false);
  }

  const handleActionButtonClick = () => {
    if(promptState === 'created'){
      if(isSuperSearching) return;
      superSearch();
    } else {
      navigate(`/prompts/${promptId}/interests`);
    }
  }

  if(promptId && !isLoadingPrompt && !readPrompt) {
    return <div className='text-error'>Error, prompt not found</div>
  };

  return (
    <div className="px-4 w-full">

      <form className="max-w-prose">
        <fieldset disabled={isCreatingPrompt || showAdvancedSettings}>
          <label className="form-control">
            <div className='label label-text'>Search generation:</div>
            <TextareaAutosize
              className='textarea textarea-bordered textarea-primary'
              placeholder="OpenAI prompt"
              value={userMessage}
              onChange={handleMainPromptChange}
              onBlur={handleMainPromptBlur}
            />
          </label>
        </fieldset>
      </form>

      <form>
        <fieldset className='form-control disabled:opacity-30 disabled:line-through' disabled={!isReadyForSuperSearch}>
          <label className='label self-end'>
            <input type="checkbox" className='mr-1' checked={showAdvancedSettings} onChange={() => setShowAdvancedSettings(!showAdvancedSettings)}/>
            <div className='label-text'>Advanced settings</div>
          </label>
        </fieldset>
      </form>

      <form className={`form-control max-w-prose ${!showAdvancedSettings && 'hidden'}`}>
        <fieldset disabled={isCreatingPrompt} className='space-y-3 flex flex-col disabled:opacity-50'>

          <label className="form-control">
            <div className="label label-text">Search generation [system]: </div>
            <TextareaAutosize disabled
              className="textarea textarea-bordered"
              value={systemMessage}
            />
          </label>

          <label className="form-control">
            <div className="label label-text">Generated list: </div>
            <TextareaAutosize disabled
              className="textarea textarea-bordered"
              value={generatedList}
            />
          </label>

          <label className="form-control">
            <div className="label label-text">Interpretation: </div>
            <TextareaAutosize
              className="textarea textarea-bordered  textarea-primary"
              placeholder="OpenAI prompt"
              value={userMessage2}
              onChange={(e) => setUserMessage2(e.target.value)}
            />
          </label>

        </fieldset>
      </form>

      <form className="form-control my-4">
        <button type="button" disabled={!isReadyForSuperSearch} className='btn btn-primary' onClick={  handleActionButtonClick }>
          {isCreatingPrompt || isRegeneratingPrompt || isRegeneratingPrompt || isSuperSearching ?
            <div className='loading'></div> :
            promptState === 'created' ? 'Super Search' : "See interests"
          }
        </button>
      </form>
    </div>
  );
}