import React, { useEffect } from "react";
import { themeChange } from 'theme-change';
import { FaCheck } from 'react-icons/fa';
//These are copied from tailwind.config.ts (From daisyUI)
const themes = [
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "corporate",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
  "dim",
  "nord",
  "sunset",
];

export default function ThemeController() {
  const [currentTheme, setCurrentTheme] = React.useState(themes[0]);
  useEffect(() => {
    themeChange(false);
    // 👆 false parameter is required for react project
    setCurrentTheme(localStorage.getItem('theme') || themes[0]);
  }, [])

  
  return (
    <div className="dropdown dropdown-end z-30">
      <div tabIndex={0} role="button" className="btn btn-sm btn-base hidden lg:flex">
        Theme
        <svg
          width="12px"
          height="12px"
          className="inline-block h-2 w-2 fill-current opacity-60"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2048 2048">
          <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
        </svg>
      </div>
      <div tabIndex={0} role="button" className="btn btn-sm btn-base lg:hidden flex">
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="h-5 w-5 stroke-current">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"></path>
        </svg>
      </div>
      <ul 
        tabIndex={0}
        className="
          dropdown-content
          bg-base-300
          rounded-box
          z-[1]
          w-56
          p-2
          shadow-2xl
          overflow-scroll
          max-h-96
          flex
          flex-col
          space-y-2
        "
      >
        {themes.map(
          (theme, idx) => (
            <button key={theme} className="outline-base-content text-start outline-offset-4" data-set-theme={theme} onClick={() => setCurrentTheme(theme)}>
              <span className="bg-base-100 rounded-btn text-base-content block w-full cursor-pointer font-sans" data-theme={theme}>
                <span className="grid grid-cols-5 grid-rows-3">
                  <span className="col-span-5 row-span-3 row-start-1 flex items-center gap-2 px-4 py-3">
                    {currentTheme === theme && 
                      <FaCheck/>
                    }
                    <span className="flex-grow text-sm capitalize">{theme}</span>
                    <span className="flex h-full shrink-0 flex-wrap gap-1">
                      <span className="bg-primary rounded-badge w-2"></span>
                      <span className="bg-secondary rounded-badge w-2"></span>
                      <span className="bg-accent rounded-badge w-2"></span>
                      <span className="bg-neutral rounded-badge w-2"></span>
                    </span>
                  </span>
                </span>
              </span>
            </button>

          ))}
      </ul>
    </div>
  )
}