import React, { useState, useCallback, useMemo } from 'react';
import { FluxTask, FluxTaskStatus } from '@prisma/client';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import { FluxPro11Input } from '@shared/types';
import { Link } from 'react-router-dom';
import { MdOutlineImageNotSupported, MdWarning, MdFileDownloadDone } from 'react-icons/md';
import { IoReload, IoEye } from 'react-icons/io5';
import VirtualList from 'components/utility/VirtualList';
import { CgSpinner } from 'react-icons/cg';

// Define the FluxTask with additional properties
type EnhancedFluxTask = FluxTask & { 
  requestOptions: FluxPro11Input; 
  thumbnailUrl: string;
};

// Define an interface for the indicator item
interface IndicatorItem {
  id: string;
  isIndicator: true;
}

// Define a union type for all possible list items
type ListItem = EnhancedFluxTask | IndicatorItem;

// Define the response structure
interface FluxTasksResponse {
  tasks: EnhancedFluxTask[];
  pagination: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
  };
}

export default function FluxAIList() {
  const { axiosInstance } = useAxios();
  
  // Function to fetch tasks with pagination
  const fetchTasks = async ({ pageParam = 1 }) => {
    const params = new URLSearchParams({
      page: pageParam.toString(),
      limit: '15' // Batch size for pagination
    });
    
    const res = await axiosInstance.get(`flux?${params.toString()}`);
    return res.data as FluxTasksResponse;
  };
  
  // Use react-query's useInfiniteQuery for data fetching with infinite scrolling
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
    refetch
  } = useInfiniteQuery({
    queryKey: ['fluxTasks'],
    queryFn: fetchTasks,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { pagination } = lastPage;
      return pagination.page < pagination.totalPages ? pagination.page + 1 : undefined;
    },
  });
  
  // Flatten all pages of data into a single array
  const allTasks = useMemo(() => {
    return data?.pages.flatMap(page => page.tasks) || [];
  }, [data]);
  
  // Create a list that includes our indicators at the end when needed
  const listWithIndicators = useMemo(() => {
    // Start with all tasks
    const list = [...allTasks] as ListItem[];
    
    // Add a special item for loading or end of list indicator
    if (allTasks.length > 0) {
      list.push({ id: 'indicator', isIndicator: true });
    }
    
    return list;
  }, [allTasks, isFetchingNextPage, hasNextPage]);
  
  // Handle intersection observer for infinite scrolling
  const handleLastItemVisible = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  
  // Format date for display
  const formatDate = (dateString?: Date | string | null) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };
  
  // Render the list of tasks
  const renderItem = useCallback((item: ListItem, index: number) => {
    // Check if this is our indicator item
    if ('isIndicator' in item) {
      if (isFetchingNextPage) {
        return (
          <div className="flex justify-center items-center py-4 w-full">
            <div className="animate-spin">
              <CgSpinner size={24} />
            </div>
          </div>
        );
      } else if (!hasNextPage) {
        return (
          <div className="flex justify-center items-center py-4 w-full">
            <MdFileDownloadDone size={24} />
          </div>
        );
      }
      return null;
    }
    
    // If this is the last actual task item, trigger loading next page
    if (index === allTasks.length - 1) {
      setTimeout(handleLastItemVisible, 100);
    }
    
    // Now TypeScript knows 'item' is an EnhancedFluxTask
    return (
      <div className="card image-full bg-base-200 h-full mb-2" style={{ maxHeight: '250px' }}>
        {item.wasModerated && (
          <div className="absolute top-2 left-2 z-10 text-red-500">
            <MdWarning size={24} />
          </div>
        )}
        
        {item.thumbnailUrl ? (
          <figure className="w-full overflow-hidden">
            <img 
              src={item.thumbnailUrl} 
              alt={item.requestOptions?.prompt || 'FluxAI image'} 
              className="w-full h-full object-cover" 
              loading="lazy"
            />
          </figure>
        ) : (
          <figure>
            <div className="flex items-center justify-center w-full h-full bg-gray-300">
              <MdOutlineImageNotSupported size={48} />
              <span className="text-gray-500">No image available</span>
            </div>
          </figure>
        )}
        
        <div className="card-body">
          <div className="flex flex-col h-full justify-between items-start card-text">
            <p className="card-title text-sm line-clamp-2">{item.requestOptions?.prompt || 'No prompt'}</p>
            
            <div className="flex justify-between w-full gap-2 items-end">
              <div className="text-xs my-2 sm:my-4">
                <p>{item.requestOptions?.width} &times; {item.requestOptions?.height}</p>
                
                <div className="flex items-center gap-2">
                  <p>{item.status}</p>
                  <p>{item.createdAt ? formatDate(item.createdAt) : ''}</p>
                </div>
              </div>

              <Link to={`/fluxai/${item.id}`} className="btn btn-sm btn-primary">
                <IoEye size={18} />
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }, [allTasks.length, formatDate, handleLastItemVisible, hasNextPage, isFetchingNextPage]);
  
  // Generate stable keys for items including the indicator
  const getItemKey = useCallback((item: ListItem) => {
    return 'isIndicator' in item ? 'indicator' : item.id;
  }, []);

  return (
    <div className="container mx-auto p-2 sm:p-4 flex flex-col h-[calc(100vh-4rem)]">
      {/* Header with title and create button */}
      <div className="flex justify-between mb-2">
        <h1 className="text-xl sm:text-2xl font-bold">FluxAI</h1>
                  
        <Link to="/fluxai/create" className="btn btn-sm btn-primary">
          <span className="hidden sm:inline">Create</span>
          <span className="sm:hidden">+</span>
        </Link>
        
      </div>
      
      {/* Loading and error states */}
      {isLoading && (
        <div className="flex justify-center items-center flex-grow">
          <div className="loading loading-spinner loading-lg"></div>
        </div>
      )}
      
      {isError && (
        <div className="alert alert-error">
          <p>Error loading tasks: {error instanceof Error ? error.message : 'Unknown error'}</p>
        </div>
      )}
      
      {/* No results */}
      {!isLoading && !isError && allTasks.length === 0 && (
        <div className="flex flex-col justify-center items-center flex-grow text-center p-4">
          <p className="text-lg mb-4">No FluxAI tasks found</p>
          <Link to="/fluxai/create" className="btn btn-primary">Create your first task</Link>
        </div>
      )}
      
      {/* Tasks list with VirtualList */}
      {!isLoading && !isError && allTasks.length > 0 && (
        <div className="flex-grow">
          <VirtualList
            items={listWithIndicators}
            renderItem={renderItem}
            getItemKey={getItemKey}
            height="calc(100vh - 8rem)"
            estimatedSize={350}
            containerClassName="w-full"
            wrapperClassName="max-w-prose mx-auto"
          />
        </div>
      )}
    </div>
  );
}