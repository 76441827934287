import React, { useState } from 'react';
import useAxios from 'hooks/useAxios';
import { FaChevronRight } from 'react-icons/fa';
import { useToast } from 'components/Toast';
import { useNavigate } from 'react-router-dom';
export default function FluxAIPro11Create() {
  const { axiosInstance } = useAxios();
  const { showToast } = useToast();

  // Form state
  const [formData, setFormData] = useState({
    prompt: '',
    image_prompt: '',
    width: 1024,
    height: 768,
    prompt_upsampling: false,
    seed: undefined as number | undefined,
    safety_tolerance: 2,
    output_format: 'jpeg' as 'jpeg' | 'png',
  });

  // Task state
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const navigate = useNavigate();

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // Handle different input types
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else if (type === 'number') {
      const numberValue = value === '' ? undefined : Number(value);
      setFormData(prev => ({ ...prev, [name]: numberValue }));
    } else if (name === 'image_prompt') {
      // Check if the value is a valid base64 string
      const isBase64Image = value.startsWith('data:image') || 
                           (value.startsWith('data:application/octet-stream') && 
                            /^data:.*;base64,([A-Za-z0-9+/=]*)$/.test(value));
      if (value && !isBase64Image) {
        showToast('Image prompt must be a valid base64 string', 'error');
        return;
      }
      setFormData(prev => ({ ...prev, [name]: value }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      // Only include fields that have values
      const payload = Object.entries(formData).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== '') {
          acc[key as keyof typeof formData] = value;
        }
        return acc;
      }, {} as Record<string, string | number | boolean | undefined>);

      const response = await axiosInstance.post('flux/create-flux-pro-11-task', payload);
      const id = response.data.id;

      if(id) {
        // Navigate to the task details page
        navigate(`/fluxai/${id}`, { replace: true });
        showToast('Task created successfully! Redirecting to details page...', 'success');
      }
    } catch (err) {
      showToast(err instanceof Error ? err.message : 'An error occurred during the request', 'error');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className='max-w-prose mx-auto'>
        <h1 className="text-xl font-bold mb-4">Flux Pro 1.1 - Text to Image</h1>
        
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 max-w-prose">
          {/* Text Prompts */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Text Prompt</span>
            </label>
            <textarea 
              required
              className="textarea textarea-bordered h-20"
              placeholder="Describe the image you want to generate..."
              name="prompt"
              value={formData.prompt}
              onChange={handleInputChange}
            />
          </div>

          {/* Image Dimensions */}
          <div className="grid grid-cols-2 gap-2">
            <div className="form-control">
              <label className="label">
                <span className="label-text font-medium">Width</span>
              </label>
              <input 
                type="number"
                className="input input-sm input-bordered w-full"
                placeholder="1024"
                name="width"
                value={formData.width === undefined ? '' : formData.width}
                onChange={handleInputChange}
                min="256"
                max="1440"
                step="32"
              />
            </div>
            
            <div className="form-control">
              <label className="label">
                <span className="label-text font-medium">Height</span>
              </label>
              <input 
                type="number"
                className="input input-sm input-bordered w-full"
                placeholder="768"
                name="height"
                value={formData.height === undefined ? '' : formData.height}
                onChange={handleInputChange}
                min="256" 
                max="1440"
                step="32"
              />
            </div>
          </div>
          
          {/* Advanced Options Toggle - Very Compact */}
          <div className='mt-3 bg-base-200 p-4'>
            <button 
              type="button"
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="text-xs text-opacity-70 hover:text-opacity-100 transition-colors flex items-center"
            >
              <span className={`mr-2 transition-transform ${showAdvanced ? 'rotate-90' : ''}`}>
                <FaChevronRight/>
              </span>
              Advanced Options
            </button>
            
            {/* Advanced Options Content */}
            {showAdvanced && (
              <div className="pt-4 space-y-3">
                <div className="form-control">
                  <label className="flex items-center gap-2 cursor-pointer">
                    <input 
                      type="checkbox" 
                      className="toggle toggle-sm" 
                      name="prompt_upsampling"
                      checked={formData.prompt_upsampling}
                      onChange={handleInputChange}
                    />
                    <span className="label-text text-xs">Prompt Upsampling</span>
                  </label>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-xs">Image Prompt</span>
                  </label>
                  <textarea 
                    className="textarea textarea-bordered h-20 text-xs"
                    placeholder="Optional base64 encoded image to use with Flux Redux."
                    name="image_prompt"
                    value={formData.image_prompt}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-xs">Randomness Seed (Repeatable results)</span>
                  </label>
                  <input 
                    type="number"
                    className="input input-xs input-bordered w-full"
                    placeholder="Random seed if empty"
                    name="seed"
                    value={formData.seed === undefined ? '' : formData.seed}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-xs">Safety Tolerance</span>
                  </label>
                  <div className='flex justify-between text-mini p-1'>
                    <span>Safe</span>
                    <span>Unsafe</span>
                  </div>
                  <input 
                    type="range"
                    className="range"
                    placeholder="1"
                    name="safety_tolerance"
                    value={formData.safety_tolerance === undefined ? '' : formData.safety_tolerance}
                    onChange={handleInputChange}
                    min="0"
                    max="6"
                    step="1"
                  />
                </div>
                
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-xs">Output Format</span>
                  </label>

                  <div className="btn-group">  
                    <button
                      type="button"
                      className={`btn btn-sm flex-1 rounded-r-none ${formData.output_format === 'jpeg' ? 'btn-active' : ''}`}
                      onClick={() => handleInputChange({ target: { name: 'output_format', value: 'jpeg' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                      JPEG
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm flex-1 rounded-l-none ${formData.output_format === 'png' ? 'btn-active' : ''}`}
                      onClick={() => handleInputChange({ target: { name: 'output_format', value: 'png' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                      PNG
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Form Submission */}
          <button 
            type="submit" 
            className="btn btn-primary btn-sm w-full my-3"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span className="loading loading-spinner loading-xs"></span>
                Generating...
              </>
            ) : 'Generate Image'}
          </button>
        </form>
      </div>
              
    </div>
  );
}