import React, { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from 'contexts/Auth';
import { FaUser, FaKey } from 'react-icons/fa';

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const location = useLocation();
  const { login, authData } = useAuth();

  const triggerLoginRoutes = [
    '/userTest',
  ]

  // Check if there is a route to return to after login
  const from = location.state?.from?.pathname || '/'; // Default to home page if no "from"
  const isTriggerLoginRoute = triggerLoginRoutes.includes(from);
  const redirectTo = isTriggerLoginRoute ? '/' : from;
  const replacePreviousRoute = isTriggerLoginRoute ? false : true;

  if(authData?.user?.username) {
    return <Navigate to={redirectTo} replace={replacePreviousRoute} />;
  }
  
  return (
    // Wrapper to vertically center the form
    <div className='flex h-full items-center'>
      <form className='form-control gap-4 max-w-96' onSubmit={(e) => e.preventDefault()}>
        <label className="input input-bordered flex items-center gap-2">
          <FaUser/>

          <input
            type="text"
            placeholder="Enter username or email"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
          />
        </label>

        <label className="input input-bordered flex items-center gap-2">
          <FaKey/>
          <input
            type="password"
            className="grow"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        
        <button 
          className='btn btn-primary'
          onClick={() => login({usernameOrEmail, password})}
        >Login</button>
      </form>
    </div>

  );
};

export default Login;