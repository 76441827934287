import React from "react";
import { useAuth } from "contexts/Auth";
import { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

/**
 * This is a component used in <Navigation/> to check if the user is logged in,
 * To show/block them the auth required routes
 */
const AuthRequired = () => {
  const {
    authData,
    isLoggingIn,
    isLoggedIn,
    isRefreshingToken,
    refreshToken,
  } = useAuth();

  const location = useLocation();

  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(true);

  //Shows loading states
  useEffect(() => {
    if(isRefreshingToken || isLoggingIn) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isRefreshingToken, isLoggingIn])

  //Refreshes token if it's expired
  useEffect(() => {
    if(!authData?.exp || (authData?.exp && Date.now() > authData?.exp * 1000)) {
      refreshToken();
    }
  }, [authData, refreshToken]);

  // Checks if user is logged in to show actual content
  useEffect(() => {
    if(isLoggedIn) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [isLoggedIn]);

  if(loading) {
    return <div>Loading...</div>;
  }

  // If not authenticated, redirect to login
  if (!showContent) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default AuthRequired;