import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import { useParams, useNavigate } from 'react-router-dom';
import { FluxPro11Input } from '@shared/types';
import { FluxTask, FluxTaskStatus, WebhookEvent } from '@prisma/client';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { FaQuoteLeft, FaQuoteRight, FaTrash } from 'react-icons/fa';
import config from 'config/config';
import { CgSpinner } from 'react-icons/cg';
import RecursiveObjectViewer from 'components/utility/RecursiveObjectViewer';
import { useToast } from 'components/Toast';

export default function FluxAIDetails() {
  const { axiosInstance } = useAxios();
  const { id } = useParams();
  const [fluxTask, setFluxTask] = useState<FluxTask & { requestOptions: FluxPro11Input, imageUrl: string,  } | null>(null);
  const [noImageAvailable, setNoImageAvailable] = useState(false);
  const [moderationWebhook, setModerationWebhook] = useState<WebhookEvent | null>(null);
  
  const navigate = useNavigate();
  const { showToast } = useToast();

  const { isLoading } = useQuery({
    queryKey: ['fluxai', id],
    queryFn: async () => {
      const res = await axiosInstance.get(`flux/${id}`);
      setFluxTask(res.data.task as FluxTask & { requestOptions: FluxPro11Input; imageUrl: string; });
      setModerationWebhook(res.data.moderationWebhook as WebhookEvent);
      return res.data;
    },
  });

  const { mutate: deleteFluxTask } = useMutation({
    mutationFn: async () => {
      await axiosInstance.delete(`flux/${id}`);
    },
    onSuccess: () => {
      showToast('Task deleted', 'success');
      navigate('/fluxai');
    },
    onError: (error) => {
      showToast('Failed to delete task', 'error');
    }
  });

  useEffect(() => {
    if(!isLoading) {
      if(fluxTask?.status === FluxTaskStatus.retrieved || fluxTask?.status === FluxTaskStatus.error) {
        if(!fluxTask?.imageUrl) {
          setNoImageAvailable(true);
        }
      }
    }
  }, [fluxTask, isLoading]);

  useEffect(() => {
    let eventSource: EventSource | null = null;

    if (!isLoading && !noImageAvailable) {  
      
      eventSource = new EventSource(`${config.devServerHost || ''}/api/flux/${id}/events`, {withCredentials: true});
      eventSource.onmessage = (event: MessageEvent<string>) => {
        const payload = JSON.parse(event.data);
        if(payload.type === 'successfulWebhook' && payload.taskId === id && payload.status === FluxTaskStatus.retrieved && payload.imageUrl){
          
          setFluxTask(prevState => {
            if (!prevState) return null;
            return {
              ...prevState,
              imageUrl: payload.imageUrl,
              status: payload.status as FluxTaskStatus
            };
          });
        }
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [isLoading, noImageAvailable, id]);

  return <div className='container mx-auto p-4 lg:pb-16'>

    <div className='mb-4 min-h-48'>
      {fluxTask?.imageUrl ? (
        <div>
          <img src={fluxTask?.imageUrl} alt={fluxTask?.requestOptions?.prompt} />
          <p className="text-sm text-gray-500 text-right">{fluxTask?.requestOptions.width} &times; {fluxTask?.requestOptions.height} | {fluxTask?.requestOptions.output_format} </p>
        </div>
      ) : noImageAvailable ? (
        <div className='flex items-center justify-center w-full h-48 bg-gray-300'>
          <MdOutlineImageNotSupported size={48} />
          <span className="text-gray-500">No image available</span>
        </div>
      ) : (
        <div className='flex items-center justify-center w-full h-48 bg-gray-300'>
          <span className='animate-spin mr-2'>
            <CgSpinner size={24} />
          </span>
          <span className="text-gray-500">Awaiting image...</span>
        </div>
      )}
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
      <div className='md:col-start-1 md:row-start-1'>
        <div className='p-4 bg-base-200 rounded-md flex items-center justify-between'>
          <span className='self-start'>
            <FaQuoteLeft />
          </span>
          <span className='flex-1 mx-2'>{fluxTask?.requestOptions?.prompt}</span>
          <span className='self-end'>
            <FaQuoteRight />
          </span>
        </div>
      </div>

      <div className='md:col-start-2 md:row-span-2'>
        <table className='table table-xs'>
          <tbody>
            <tr>
              <td>status</td>
              <td>{fluxTask?.status}</td>
            </tr>
            <tr>
              <td>was_moderated</td>
              <td>{fluxTask?.wasModerated ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>{fluxTask?.createdAt?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>updated_at</td>
              <td>{fluxTask?.updatedAt?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>prompt_upsampling</td>
              <td>{fluxTask?.requestOptions.prompt_upsampling}</td>
            </tr>
            <tr>
              <td>seed</td>
              <td>{fluxTask?.requestOptions.seed}</td>
            </tr>
            <tr>
              <td>safety_tolerance</td>
              <td>{fluxTask?.requestOptions.safety_tolerance}</td>
            </tr>
            <tr>
              <td>image_prompt</td>
              <td>{fluxTask?.requestOptions.image_prompt}</td>
            </tr>
          </tbody>
        </table>

        <button className='btn btn-error btn-sm w-full mt-4' onClick={() => deleteFluxTask()}>
          <FaTrash />
          Delete (soft)
        </button>
        
      </div>
      
      <div className='md:col-start-1 md:row-start-2 order-last md:order-none'>
        <RecursiveObjectViewer 
          data={moderationWebhook?.payload} 
          idPrefix={`moderation-webhook-${moderationWebhook?.id}`}
          wrapperComponent={({children}) => <div className='text-mini p-2 bg-base-200 rounded-lg'>{children}</div>}
        />
      </div>
    </div>

  </div>;
}
