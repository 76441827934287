import { Interest } from "@prisma/client";
import { useQuery } from "@tanstack/react-query";
import useAxios from "hooks/useAxios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PromptStats from "pages/Prompts/PromptStats";
import useGetPromptInterestsEvents from "hooks/useGetPromptInterestsEvents";
import _orderBy from 'lodash/orderBy';
import _keys from 'lodash/keys';
import VirtualList from "components/utility/VirtualList";
import InterestComponent from "pages/Prompts/InterestComponent";
import { BsSortUpAlt } from "react-icons/bs";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
const PromptDetails = () => {
  const { promptId } = useParams();

  const sortButtonRef = useRef<HTMLButtonElement>(null);

  const [interests, setInterests]           = useState<Interest[]>([]);
  const [interestsCount, setInterestsCount] = useState(0);
  const [fields, setFields]                 = useState<Set<string>>(new Set());

  const { axiosWithAuth }        = useAxios();
  const interestsFromEventSource = useGetPromptInterestsEvents(promptId);

  const { data: promptWithInterests, isLoading, error} = useQuery({
    queryKey: ['prompts', promptId, 'interests'],
    queryFn: async () => {
      return await axiosWithAuth.get(`prompts/${promptId}/interests`).then((res) => res.data);
    },
    retry: false,
  });

  useEffect(() => {
    if(!isLoading && promptWithInterests && promptWithInterests.interests) {
      setInterests(prev => [...prev, ...promptWithInterests.interests]);
      addFieldsFromInterestsArray(promptWithInterests.interests);
    }

    return () => {
      setInterests([]);
    }
  }, [promptWithInterests, isLoading])

  useEffect(() => {
    setInterests(prev => [...prev, ...interestsFromEventSource]);
  }, [interestsFromEventSource])

  useEffect(() => {
    setInterestsCount(interests.length);

    return () => setInterestsCount(0);
  }, [interests]);

  const addFieldsFromInterestsArray = (interestsArray: Interest[]) => {
    for(const currentInterest of interestsArray) {
      for(const currentField of _keys(currentInterest.data)) {
        setFields(prev => new Set(prev).add(currentField))
      }
    }
  }

  const handleSortButtonClick = (fieldName: string, order: 'asc' | 'desc') => {
    const sorted = _orderBy(interests, `data.${fieldName}`, order);
    setInterests(prev => sorted);

    sortButtonRef.current?.click();
  }
  
  return (
    <div className="w-full">
      <div className="p-4">
        <div className="flex justify-between leading-none">
          <div className="flex flex-col justify-">
            <h2 className="font-semibold shrink-0">Prompt Details</h2>  
            <small>Total interests: {isLoading ? <div className="loading text-primary w-2"></div> : interestsCount}</small>
          </div>
          <PromptStats promptId={promptId} />
        </div>

        <details className="dropdown block z-10 mt-2">
          <summary
            ref={sortButtonRef}
            tabIndex={0}
            className="btn btn-xs btn-secondary gap-1 text-mini"
          >
            <BsSortUpAlt />
            <span>Sorting</span>
          </summary>

          <div tabIndex={0} 
            className="dropdown-content mt-1 bg-secondary p-2 rounded-lg"
          >
            <div className="flex flex-row flex-wrap gap-2">
              { Array.from(fields.values()).sort().map(field => (
                <div className="join flex" style={{ minWidth: '48%' }} key={`sort-${field}`}>
                  <button className="join-item btn btn-xs" onClick={() => handleSortButtonClick(field, 'asc')}>
                    <AiOutlineSortAscending />
                  </button>

                  <span className="join-item flex-grow btn btn-xs text-tiny">{field}</span>
                  
                  <button className="join-item btn btn-xs" onClick={() => handleSortButtonClick(field, 'desc')}>
                      <AiOutlineSortDescending />
                  </button>
                </div>
              ))}
            </div>
            
          </div>
        </details>
      </div>

      {isLoading && 
        <div className="flex flex-col gap-y-3 mb-3">
          <div className="mx-4 skeleton rounded-lg max-w-prose h-24 flex bg-primary justify-center">
            <div className="loading text-primary-content"></div>
          </div>
          <div className="mx-4 skeleton rounded-lg max-w-prose h-24 flex bg-primary justify-center">
            <div className="loading text-primary-content"></div>
          </div>
          <div className="mx-4 skeleton rounded-lg max-w-prose h-24 flex bg-primary justify-center">
            <div className="loading text-primary-content"></div>
          </div>
          <div className="mx-4 skeleton rounded-lg max-w-prose h-24 flex bg-primary justify-center">
            <div className="loading text-primary-content"></div>
          </div>
          <div className="mx-4 skeleton rounded-lg max-w-prose h-24 flex bg-primary justify-center">
            <div className="loading text-primary-content"></div>
          </div>
        </div>
      }      

      {error && 
        <div className="mx-4 text-error">{error.message}</div>
      }
      
      <VirtualList 
        items={interests}
        renderItem={(interest) => <InterestComponent interest={interest} />}
        getItemKey={(interest) => interest.id}
        containerClassName="px-4"
      />
      
    </div>
  )
}

export default PromptDetails;