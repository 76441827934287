import React from 'react';
import { withDefaultClasses } from 'utils/classNames';

interface RecursiveObjectViewerProps {
  // The object to display
  data: unknown;
  // CSS class for the container
  className?: string;
  // CSS class for the content wrapper
  contentClassName?: string;
  // Component ID prefix for keys
  idPrefix?: string;
  // Optional field name (used for nested items)
  fieldName?: string;
  labelClassName?: string;
  scalarValuesClassName?: string;
  // Indicates if this is the root level (first level) of recursion
  _isRootLevel?: boolean;
  wrapperComponent?: React.ComponentType<{ children: React.ReactNode }>;
}

// Create class name helpers with default styles
const containerClasses = withDefaultClasses('bg-base-100/20 rounded-sm py-0.5 px-1');
const contentClasses = withDefaultClasses('flex flex-row flex-wrap gap-2');
const labelClasses = withDefaultClasses('text-primary-content/40 mb-0.5 mr-0.5');
const scalarValuesClasses = withDefaultClasses('break-all');

/**
 * A component that recursively renders JavaScript objects
 * Can handle strings, numbers, booleans, arrays, and nested objects
 * 
 * Usage:
 * <RecursiveObjectViewer data={myObject} />
 */
const RecursiveObjectViewer: React.FC<RecursiveObjectViewerProps> = ({
  data,
  className,
  contentClassName,
  idPrefix = '',
  fieldName,
  labelClassName,
  scalarValuesClassName,
  _isRootLevel = true, // Default to true - external calls are root level
  wrapperComponent: WrapperComponent = 'div',
}) => {
  if(_isRootLevel) {
    if(!data) return null;  

    return (
      
      <WrapperComponent>
        <RecursiveObjectViewer
          data={data}
          className={className}
          contentClassName={contentClassName}
          idPrefix={idPrefix}
          fieldName={fieldName}
          labelClassName={labelClassName}
          _isRootLevel={false} // Nested items are never root level
        />
      </WrapperComponent>
      
    );
  }

  // Handle display of primitive values
  if (typeof data !== 'object' || data === null) {
    return (
      <div className={containerClasses(className)}>
        {fieldName && <div className={labelClasses(labelClassName)}>{fieldName}</div>}
        <div className={scalarValuesClasses(scalarValuesClassName)}>
          {data === null ? 'null' : String(data)}
        </div>
      </div>
    );
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return (
      <div className={containerClasses(className)}>
        {fieldName && <div className={labelClasses(labelClassName)}>{fieldName}</div>}
        <div className={contentClasses(contentClassName)}>
          {data.map((item, index) => (
            <RecursiveObjectViewer
              key={`${idPrefix || 'item'}-${index}`}
              data={item}
              className={className}
              contentClassName={contentClassName}
              idPrefix={idPrefix ? `${idPrefix}-${index}` : `item-${index}`}
              labelClassName={labelClassName}
              _isRootLevel={false} // Nested items are never root level
            />
          ))}
        </div>
      </div>
    );
  }

  // Handle regular objects
  return (
    <div className={fieldName ? containerClasses(className) : contentClasses(contentClassName)}>
      {fieldName && <div className={labelClasses(labelClassName)}>{fieldName}</div>}
      <div className={contentClasses(contentClassName)}>
        {Object.entries(data as Record<string, unknown>).map(([key, value]) => (
          <RecursiveObjectViewer
            key={`${idPrefix || 'field'}-${key}`}
            data={value}
            className={className}
            contentClassName={contentClassName}
            idPrefix={idPrefix ? `${idPrefix}-${key}` : key}
            fieldName={key}
            labelClassName={labelClassName}
            _isRootLevel={false} // Nested items are never root level
          />
        ))}
      </div>
    </div>
  );
};

export default RecursiveObjectViewer; 