import React from 'react';
import useGetPromptJobsProgressEvents from 'hooks/useGetPromptJobsProgressEvents';


const PromptStats = ({promptId}: {promptId?: string}) => {
  
  const progress = useGetPromptJobsProgressEvents(promptId);


  return (
    <div className="flex flex-col text-tiny">
      <div className="flex justify-end space-x-5">
        <div>Queries: {progress.serperGoogleSearchesGenerated}</div>
        <div>Pages: {progress.linksGenerated}</div>
      </div>
      <Progress className="progress-primary"   label='Downloaded' successes={progress.linkScrapeSuccess}         failures={progress.linkScrapeFailure}         total={progress.linksGenerated} />
      <Progress className="progress-secondary" label='Cleaned up' successes={progress.linkParseSuccess}          failures={progress.linkParseFailure}          total={progress.linkScrapeSuccess} />
      {/* The total is linkParseSuccess, because link parse failures (for example non-valid content) will stay in "parsing" state instead of parsed, so it won't even be picked up for interpretation  */}
      <Progress className="progress-info"      label='AI'         successes={progress.linkInterpretationSuccess} failures={progress.linkInterpretationFailure} total={progress.linkParseSuccess} />
    </div>
  ); 
}

const Progress = (
  {
    className,
    label,
    successes,
    failures,
    total
  }
  :
  {
    className?: string,
    label     : string,
    successes : number,
    failures  : number,
    total     : number
  }
) => {

  return (
    <div className='flex justify-end items-center space-x-3'>
      <div>{label}</div>
      <div className='font-mono'>{successes+failures}</div>
      <progress 
        className={`progress w-32 h-1 ${className}`}
        value={successes + failures}
        max={total}
      />
    </div>
  )
}

export default PromptStats;