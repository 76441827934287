import { useQuery } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import React, { useEffect } from 'react';

export default function UserTest() {
  const {axiosWithAuth} = useAxios();

  const {data} = useQuery({
    queryKey: ['user-test'],
    queryFn: () => {
      return axiosWithAuth.get('user-test').then(res => res.data);
    }
  })

  useEffect(() => {
    if(data){
      console.log(data);
    }
  }, [data]);

  return <div> User test  </div>
  
}