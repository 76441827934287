import { useAuth } from "contexts/Auth";
import { useLocation } from "react-router-dom";

export default function useShouldShowMenu() {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const showMenuRoutes = [
    "/test",
    "/userTest",
  ];

  return isLoggedIn || showMenuRoutes.includes(location.pathname);
}
