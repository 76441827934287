import React from 'react';

export default function Test(){


  return (
    <div className='flex p-4 justify-start items-start w-full space-x-10'>
      lorem ipsum dolor sit amet
    </div>
  );
}