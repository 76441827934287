import { useQuery } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import React, { useEffect } from 'react';

export default function UserAndAdminTest() {
  const {axiosWithAuth} = useAxios();

  const {data} = useQuery({
    queryKey: ['user-and-admin-test'],
    queryFn: async () => {
      const res = await axiosWithAuth.get('user-and-admin-test');
      return res.data;
    }
  })

  useEffect(() => {
    if(data){
      console.log(data);
    }
  }, [data]);

  return <div> User and admin test  </div>
  
}