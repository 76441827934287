import React from 'react';
import { Interest } from '@prisma/client';
import RecursiveObjectViewer from '../../components/utility/RecursiveObjectViewer';
import { FaChevronRight } from "react-icons/fa";

/**
 * Component for rendering a single Interest item
 */
const InterestComponent: React.FC<{ interest: Interest }> = ({ interest }) => {
  const date = interest.createdAt ? new Date(interest.createdAt) : null;
  const [collapsed, setCollapsed] = React.useState(true);
  
  return (
    <RecursiveObjectViewer
      data={interest.data as Record<string, unknown>} 
      idPrefix={`interest-${interest.id}`}
      wrapperComponent={({children}) => (
        <div 
          className={`mb-2 rounded-lg bg-primary text-primary-content text-mini overflow-hidden ${collapsed && 'max-h-24'} ${collapsed && 'border-b-4 border-b-primary-content/20 border-double'}`}
          onClick={() => setCollapsed(!collapsed)}
          data-id={interest.id}
          data-web-page-id={interest.webPageId}
          data-created-at={date?.toISOString()}
        >
          <div className='p-2 flex flex-row flex-wrap gap-2'>
            <div className={`absolute right-3 transition-[transform] ${!collapsed && 'rotate-90'}`}>
              <FaChevronRight />
            </div>
            {children}
          </div>
        </div>
      )}
    />
  );
};

export default InterestComponent; 