import { useEffect, useState } from "react";
import { PromptStats} from "@shared/types";
import config from 'config/config';
import { useQuery } from '@tanstack/react-query';
import useAxios from './useAxios';

const emptyPromptStats: PromptStats = {
  googleSearchesGenerated       : 0,
  googleSearchesParsed          : 0,
  serperGoogleSearchesGenerated : 0,
  linksGenerated                : 0,
  linkScrapeSuccess             : 0,
  linkScrapeFailure             : 0,
  linkParseSuccess              : 0,
  linkParseFailure              : 0,
  linkInterpretationSuccess     : 0,
  linkInterpretationFailure     : 0,
  interests                     : 0
}

const useGetPromptJobsProgressEvents = (promptIdParam?: string) => {

  const [currentProgress, setCurrentProgress] = useState<PromptStats>(emptyPromptStats);
  const [getProgressFromDatabase, setGetProgressFromDatabase] = useState(false);

  const { axiosWithAuth } = useAxios();

  const { data } = useQuery({
    queryKey: ['prompts', promptIdParam, 'stats'],
    queryFn: async () => {
      return await axiosWithAuth(`prompts/${promptIdParam}/stats`).then((res) => res.data);
    },
    enabled: getProgressFromDatabase
  });

  useEffect(() => {
    const eventSource = new EventSource(`${config.devServerHost || ''}/api/prompts/${promptIdParam}/progress/`, {withCredentials: true});

    eventSource.onmessage = (event) => {
      try{
        setCurrentProgress(JSON.parse(event.data));
      } catch (e){
        if(e instanceof SyntaxError){
          if(
            event.data.includes('Initial stats not found') ||
            event.data.includes('Emitter not found')
          ){
            setGetProgressFromDatabase(true);
            eventSource.close();
          } else if( event.data === 'completed') {
            console.info('Progress completed, closing SSE');
            eventSource.close();
          }
        } else {
          console.error(e);
        }
      }
    };    

    eventSource.onerror = (e) => {
      console.error(e);
      eventSource.close();
    };

    return () => {
      eventSource.close();  
      setCurrentProgress(emptyPromptStats)
    }
  },[promptIdParam]);

  useEffect(() => {
    if(data){
      setCurrentProgress(data);
    }
  }, [data]);

  return currentProgress as PromptStats;

};

export default useGetPromptJobsProgressEvents;