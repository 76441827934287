import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/Auth";
import useShouldShowMenu from "hooks/useShouldShowMenu";
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';

export default function Drawer() {
  const shouldShowMenu = useShouldShowMenu();

  const { isLoggedIn, authData, logout } = useAuth();
  
  const closeDrawer = () => {
    const drawerCheckbox = document.getElementById('my-drawer-3') as HTMLInputElement;
    if (drawerCheckbox) {
      drawerCheckbox.checked = false;
    }
  };

  if (!shouldShowMenu) {
    return null;
  } 

  return (
    <div className="drawer-side lg:hidden z-50">

      <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label>            

      <ul className="menu bg-base-200 min-h-full w-40 p-4 opacity-95 gap-1">
        {/* Sidebar content here */}
        {isLoggedIn && authData?.user?.username &&
          <li className='flex flex-col'>
            <div className='font-semibold'>
              <FaUserCircle size={20}/>
              <span>{authData.user.username}</span>
            </div>
          </li>
        }

        <li><Link to="/prompts" onClick={closeDrawer}>Prompts</Link></li>
        <li><Link to="/fluxai" onClick={closeDrawer}>FluxAI</Link></li>

        <li>
          <details>
            <summary className="cursor-pointer">Debug</summary>
            <div className="mt-1 ml-6 flex flex-col gap-4">
              <Link to="/refresh-token-test" onClick={closeDrawer}>Refresh Token Test</Link>
              <Link to="/test" onClick={closeDrawer}>Test</Link>
              <Link to="/userTest" onClick={closeDrawer}>User Test</Link>
              <Link to="/userAndAdminTest" onClick={closeDrawer}>User and Admin Test</Link>
            </div>
          </details>
        </li>
        
        <li></li>
        {isLoggedIn && 
          <li>
            <button className='btn btn-sm btn-ghost' onClick={logout}>
              <FaSignOutAlt size={20}/>
              Logout
            </button>
          </li>
        }
      </ul>   
    </div>   
  )
}