import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { FaCheckCircle, FaInfoCircle, FaExclamationTriangle, FaTimesCircle, FaTimes } from 'react-icons/fa';

interface ToastMessage {
  id: string;
  message: string;
  type: 'info' | 'success' | 'warning' | 'error';
  duration?: number;
}

interface ToastContextType {
  showToast: (message: string, type?: ToastMessage['type'], duration?: number) => void;
  hideToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const showToast = (
    message: string, 
    type: ToastMessage['type'] = 'info', 
    duration = 3000
  ) => {
    const id = Math.random().toString(36).substring(2, 9);
    setToasts(prev => [...prev, { id, message, type, duration }]);
    return id;
  };

  const hideToast = (id: string) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  };

  // Auto dismiss toasts after their duration
  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];
    
    toasts.forEach(toast => {
      const timer = setTimeout(() => {
        hideToast(toast.id);
      }, toast.duration || 3000);
      
      timers.push(timer);
    });

    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      
      {/* Toast container - centered at the bottom of the screen */}
      <div className="toast toast-center z-50">
        {toasts.map((toast) => (
          <div 
            key={toast.id} 
            className={`alert ${getAlertClass(toast.type)} shadow-lg max-w-md`}
          >
            <div className="flex justify-between w-full">
              <div className="flex items-center gap-2">
                {getIcon(toast.type)}
                <span>{toast.message}</span>
              </div>
              <button 
                onClick={() => hideToast(toast.id)} 
                className="btn btn-ghost btn-xs"
              >
                <FaTimes size={16} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

// Helper functions for styling
function getAlertClass(type: ToastMessage['type']): string {
  switch (type) {
    case 'success': return 'alert-success';
    case 'warning': return 'alert-warning';
    case 'error': return 'alert-error';
    case 'info':
    default: return 'alert-info';
  }
}

function getIcon(type: ToastMessage['type']): JSX.Element {
  const iconSize = 20;
  
  switch (type) {
    case 'success':
      return <FaCheckCircle size={iconSize} />;
    case 'warning':
      return <FaExclamationTriangle size={iconSize} />;
    case 'error':
      return <FaTimesCircle size={iconSize} />;
    case 'info':
    default:
      return <FaInfoCircle size={iconSize} />;
  }
} 