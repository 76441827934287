import { useAuth } from 'contexts/Auth';
import React from 'react';
import {useMutation} from "@tanstack/react-query";
import useAxios from "hooks/useAxios";

export default function RefreshTokenTest(){

  const {authData, refreshToken} = useAuth();
  const {axiosWithAuth} = useAxios();

  const {mutate} = useMutation({
    mutationKey: ['token', 'test'],
    mutationFn: () => {
      return axiosWithAuth.get('token/test');
    }
  })

  return (
    <div className='flex p-4 justify-start items-start w-full space-x-10'>
      <button
        className='btn btn-primary btn-sm'
        onClick={refreshToken}
      >Refresh Token
      </button>

      <button
        className='btn btn-primary btn-sm'
        onClick={() => mutate()}
      >Passively refresh
      </button>

      <div>
        Expiration: {new Date((authData?.exp || 1) * 1000).toJSON()}
      </div>
    </div>
  );
}