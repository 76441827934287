import { Interest } from '@prisma/client';
import { useEffect, useState } from "react";
import config from 'config/config';

const useGetPromptInterestsEvents = (promptIdParam?: string) => {
  const [interests, setInterests] = useState<Interest[]>([]);
 
  useEffect(() => {
    const eventSource = new EventSource(`${config.devServerHost || ''}/api/interests/interests-event-source/${promptIdParam}`, {withCredentials: true});

    eventSource.onmessage = (event) => {
      setInterests(JSON.parse(event.data));
    };

    eventSource.onerror = (e) => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
      setInterests([]);
    }
  },[promptIdParam]);

  return interests;

};

export default useGetPromptInterestsEvents;