import React from 'react';
import PromptCreate from 'pages/Prompts/PromptCreate';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Test from '../pages/Test';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import PromptsList from 'pages/Prompts/PromptsList';
import AuthRequired from 'components/AuthRequired';
import PromptDetails from 'pages/Prompts/PromptDetails';
import UserTest from 'pages/UserTest';
import UserAndAdminTest from 'pages/UserAndAdminTest';
import RefreshTokenTest from "../pages/RefreshTokenTest";
import Menu from './TopMenu';
import Drawer from './Drawer';
import FluxAIPro11Create from 'pages/FluxAI/FluxAIPro11Create';
import FluxAIList from 'pages/FluxAI/FluxAIList';
import FluxAIDetails from 'pages/FluxAI/FluxAIDetails';
// Component to handle navigation with route-based visibility
function NavigationContent() {

  return (
    <div className="drawer h-full">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />

      <div className="drawer-content flex flex-col items-center justify-center h-full">
        
        <Menu/>

        <div className='flex justify-center h-full w-full'>
          <Routes>
            {/* Starting Routes */}
            <Route path="/" element={<Home />} />

            {/* Public Routes */}
            <Route path="/test" element={<Test />} />
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<AuthRequired />}>
              <Route path="/prompts" element={<PromptsList />} />
              <Route path="/prompts/create" element={<PromptCreate />} />
              <Route path="/prompts/:promptId" element={<PromptCreate />} />
              <Route path="/prompts/:promptId/interests" element={<PromptDetails />} />
              <Route path="/fluxai/create" element={<FluxAIPro11Create />} />
              <Route path="/fluxai" element={<FluxAIList />} />
              <Route path="/fluxai/:id" element={<FluxAIDetails />} />
              <Route path="/logout" element={<Logout />} />
              
              <Route path="/userTest" element={<UserTest />} />
              <Route path="/refresh-token-test" element={<RefreshTokenTest/>} />
              <Route path="/userAndAdminTest" element={<UserAndAdminTest />} />
            </Route>
          </Routes>
        </div>
      </div>

      <Drawer />
    </div>
  );
}

export default function Navigation() {
  return (
    <BrowserRouter>
      <NavigationContent />
    </BrowserRouter>
  );
}