import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ThemeController from "./ThemeController";
import useShouldShowMenu from "hooks/useShouldShowMenu";
import { useAuth } from "contexts/Auth";
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaChevronDown } from 'react-icons/fa';

export default function Menu() {
  const shouldShowMenu = useShouldShowMenu();
  const { isLoggedIn, authData, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Handle navigation and close dropdown
  const handleNavigation = (path: string) => {
    setIsOpen(false);
    navigate(path);
  };

  if (!shouldShowMenu) {
    return null;
  }

  return (
    <div className="navbar bg-base-300 p-3">
            
      {/* Burger menu */}
      <div className="flex-none lg:hidden">
        <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost">
          <span className="size-6 flex items-center justify-center">
            <GiHamburgerMenu size={24} />
          </span>
        </label>
      </div>
      
      <Link to="/" className="text-xl font-extrabold mx-2">Funbuddy.ai</Link>

      {/* Navbar on desktop */}
      <div className='navbar-start space-x-4 hidden lg:flex ml-12'> 
        <Link className='btn btn-sm btn-primary' to="/prompts">Prompts</Link>
        <Link className='btn btn-sm btn-primary' to="/fluxai">FluxAI</Link>
        <div className="dropdown" ref={dropdownRef}>
          <div 
            tabIndex={0} 
            role="button" 
            className="btn btn-sm btn-primary"
            onClick={() => setIsOpen(!isOpen)}
          >
            Debug
            <span className="ms-1">
              <FaChevronDown size={16} />
            </span>
          </div>
          <ul tabIndex={0} className={`dropdown-content z-30 menu p-2 shadow bg-base-200 rounded-box w-52 ${isOpen ? 'block' : 'hidden'}`}>
            <li><button onClick={() => handleNavigation('/refresh-token-test')}>Refresh Token Test</button></li>
            <li><button onClick={() => handleNavigation('/test')}>Test</button></li>
            <li><button onClick={() => handleNavigation('/userTest')}>User Test</button></li>
            <li><button onClick={() => handleNavigation('/userAndAdminTest')}>User and Admin Test</button></li>
          </ul>
        </div>
      </div>

      <div className='navbar-end pr-2 space-x-4 flex-grow'>

          {isLoggedIn && authData?.user?.username &&
            <>
              <div className='capitalize font-semibold hidden lg:flex'>Welcome, {authData.user.username}</div>
              <button className='btn btn-sm btn-primary hidden lg:flex' onClick={logout}>Logout</button>
            </>
          }

          {
            !isLoggedIn &&
            <Link className='btn btn-sm btn-primary hidden lg:flex' to="/login">Login</Link>
          }

        <ThemeController />
      </div>
    </div>
  )
}