import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Navigation from 'components/Navigation';
import { AuthProvider } from 'contexts/Auth';
import { ToastProvider } from 'components/Toast';

const queryClient = new QueryClient();

const App = () => (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastProvider>
          <Navigation/>
        </ToastProvider>
      </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>    
  </React.StrictMode>
)

export default App;