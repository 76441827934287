import React, { useRef } from 'react';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';

export interface VirtualListProps<T> {
  /** Array of items to render */
  items: T[];
  /** Component to render each individual item */
  renderItem: (item: T, index: number) => React.ReactNode;
  /** Optional unique key extraction function (defaults to index) */
  getItemKey?: (item: T, index: number) => string | number;
  /** Container height (default: 80vh) */
  height?: string | number;
  /** Estimated item size for initial rendering (default: 50px) */
  estimatedSize?: number;
  /** Additional CSS classes for the container */
  containerClassName?: string;
  /** Additional CSS classes for the wrapper */
  wrapperClassName?: string;
}

/**
 * A generic virtualized list component for efficient rendering of large lists
 * Powered by TanStack Virtual
 */
function VirtualList<T>({
  items,
  renderItem,
  getItemKey = (_, index) => index,
  height = '80vh',
  estimatedSize = 50,
  containerClassName = '',
  wrapperClassName = '',
}: VirtualListProps<T>) {
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimatedSize,
  });

  return (
    <div 
      className={containerClassName} 
      ref={parentRef} 
      style={{ height, overflow: 'auto' }}
    >
      <div
        className={wrapperClassName}
        style={{
          height: virtualizer.getTotalSize(),
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((virtualItem: VirtualItem) => {
          const item = items[virtualItem.index];
          return (
            <div
              key={getItemKey(item, virtualItem.index)}
              id={`virtual-item-${virtualItem.index}`}
              ref={virtualizer.measureElement}
              data-index={virtualItem.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                transform: `translateY(${virtualItem.start}px)`,
                width: '100%',
              }}
            >
              {renderItem(item, virtualItem.index)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VirtualList; 