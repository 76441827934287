import { Prompt } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';
import useAxios from 'hooks/useAxios';
import React from 'react';
import { Link } from 'react-router-dom';
import _last from 'lodash/last';
import { OpenAIDataInteraction } from '@shared/types';

export default function PromptsList(){

  const { axiosWithAuth } = useAxios();

  const {data, isLoading} = useQuery({
    queryKey: ['prompts'],
    queryFn: async () => {
      return await axiosWithAuth.get('prompts').then((res) => res.data);
    }
  });

  return (
    <div className='p-4 text-left w-full'>
      <div className='mb-4 flex justify-between items-center'>
        <h1 className='text-2xl'>Prompts list</h1>
        <Link className='btn btn-primary btn-sm' to="/prompts/create">
          New Prompt
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
        </Link>
      </div>

      {isLoading && <p>Loading...</p>}
      
      <div className='text-sm flex flex-col gap-3'>
        {data && data.map(
          (prompt: Prompt) => {
            const lastInteraction = _last(prompt.openAIDataInteractions as unknown as OpenAIDataInteraction[]);
            return (
            <div key={prompt.id}>
              <div className='text-mini capitalize'>{prompt.state}</div>
              <div 
                className="flex gap-3 items-center"            
              >

                <Link className='line-clamp-3 text-primary' to={`/prompts/${prompt.id}/interests`}>{lastInteraction?.request.messages[0].content as string || 'Unknown prompt'}</Link>

                <Link to={`/prompts/${prompt.id}/`} className='btn btn-secondary btn-xs join-item'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                  </svg>
                </Link>
              </div>
            </div>  
            )
          })
        }
      </div>
    </div>
    
  )
}