import { useMutation } from '@tanstack/react-query';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { User } from '@prisma/client';
import {jwtDecode, JwtPayload} from 'jwt-decode';
import useAxios from 'hooks/useAxios';

interface AuthContextType {
  login: ({
    usernameOrEmail,
    password
  } : {
    usernameOrEmail: string,
    password: string
  }) => void;
  logout: () => void;
  refreshToken: () => void;
  isRefreshingToken: boolean;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  authData: AuthWithUser;
  test: () => void,
  decodeAndMapTokenIntoState: (token: string) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export type AuthWithUser = JwtPayload & {user: Partial<User>} | null;

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [authData, setAuthData] = useState<AuthWithUser>(null);

  const { axiosInstance, axiosWithAuth } = useAxios();

  const decodeAndMapTokenIntoState = (token: string) => {
    const decoded = jwtDecode(token) as JwtPayload & { user?: Partial<User> };
    
    // Ensure we maintain the AuthWithUser structure and properly merge user properties
    const decodedAuth: AuthWithUser = {
      ...decoded,
      user: decoded.user || {}
    };

    setAuthData((prevState: AuthWithUser) => {
      if (!prevState) return decodedAuth;
      
      return {
        ...prevState,
        ...decodedAuth,
        user: {
          ...prevState.user,
          ...decodedAuth.user
        }
      };
    });
  }

  const unsetTokenData = () => {
    setAuthData(null);
  }

  const { mutate: login, isPending: isLoggingIn } = useMutation({
    mutationKey: ['login'],
    mutationFn: async (
      {
        usernameOrEmail,
        password
      } : {
        usernameOrEmail: string,
        password: string
      }) => {
      return await axiosInstance.post('user/login', {usernameOrEmail, password});
    },
    onSuccess: response => {
      decodeAndMapTokenIntoState(response.data.accessToken)
      decodeAndMapTokenIntoState(response.data.refreshToken)
    },
    onError: unsetTokenData
  });

  const { mutate: logout } = useMutation({
    mutationKey: ['logout'],
    mutationFn: async () => {
      return await axiosInstance.post('user/logout');
    },
    onSuccess: unsetTokenData,
  });

  const { mutate: refreshToken, isPending: isRefreshingToken } = useMutation({
    mutationKey: ['token/refresh'],
    mutationFn: async () => {
      return await axiosWithAuth.post('token/refresh');
    },
    onSuccess: response => {
      decodeAndMapTokenIntoState(response.data.accessToken);
      decodeAndMapTokenIntoState(response.data.refreshToken);
    },
    onError: unsetTokenData
  });

  useEffect(() => {
    if(authData && Object.keys(authData).length > 0) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [authData]);

  const value = { 
    authData,
    login,
    logout,
    isLoggedIn,
    isLoggingIn,
    refreshToken,
    isRefreshingToken,
    test: () => { console.log('test') },
    decodeAndMapTokenIntoState
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const authContext = useContext(AuthContext);
  if(authContext === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
}