import { useAuth } from "contexts/Auth";
import React from "react";
import { Navigate } from "react-router-dom";

export default function Logout(){
  const { logout } = useAuth();
  logout();

  return (
    <Navigate to="/home"/>
  );
};

