import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Combines multiple class names into a single string, resolving Tailwind CSS conflicts.
 * 
 * @param inputs - Any number of class name inputs (strings, objects, arrays)
 * @returns A merged string of class names with resolved conflicts
 * 
 * @example
 * // Basic usage
 * cn('bg-base-100/20 rounded-sm py-0.5 px-1', 'text-sm')
 * // => 'bg-base-100/20 rounded-sm py-0.5 px-1 text-sm'
 * 
 * @example
 * // With conditional classes
 * cn('bg-base-100/20', { 'text-red-500': isError, 'font-bold': isActive })
 * // => 'bg-base-100/20 text-red-500 font-bold' (if both conditions are true)
 * 
 * @example
 * // With conflicting classes (later classes override earlier ones)
 * cn('bg-blue-500 p-2', 'bg-red-500 p-4')
 * // => 'bg-red-500 p-4' (later values take precedence)
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
} 

/**
 * Creates a function that combines default class names with custom ones.
 * 
 * @param defaultClasses - The default classes to be applied
 * @returns A function that combines default classes with custom ones
 * 
 * @example
 * // Create a button class helper with default styles
 * const buttonClasses = withDefaultClasses('bg-blue-500 text-white py-2 px-4 rounded');
 * 
 * // Use the helper in a component
 * <button className={buttonClasses('hover:bg-blue-600')} />
 * // => 'bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'
 * 
 * // Custom classes can override defaults
 * <button className={buttonClasses('bg-red-500')} />
 * // => 'text-white py-2 px-4 rounded bg-red-500'
 */
export function withDefaultClasses(defaultClasses: string) {
  /**
   * Combines the default classes with the provided custom classes
   * 
   * @param customClasses - Optional custom classes to combine with defaults
   * @returns Combined class string with conflicts resolved
   */
  return function(customClasses?: string): string {
    return cn(defaultClasses, customClasses);
  };
}