import React from "react";
import { useAuth } from "contexts/Auth";

export default function Home() {
  const { authData } = useAuth();

  if (!authData?.user?.username) {
    return <div className="flex flex-col h-full items-center justify-center">
      <h1 className="text-3xl font-bold">Funbuddy.ai</h1>
      <h2 className="text-xl">AI-powered fun</h2>
    </div>;
  }

  return (
    <div className="flex h-full items-center">
      <div>
        <h1 className="text-3xl font-bold">Welcome, { authData?.user?.username }</h1>
      </div>
    </div>

  );
}